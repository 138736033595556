import React, { useCallback, useEffect, useRef } from "react";
import { PageWrapper } from "src/components";
import { LandingPage } from "./landing_page";
import { TsAndCsPage } from "./ts_and_cs_page";
import { QuestionnairePage } from "./questionnaire_page";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { getQuestionnaire } from "src/services/apiService";
import { fetchData } from "src/store/slices/questionnaireSlice";
import { nextPage } from "src/store/slices/pageSlice";
import { SuccessPage } from "./success_page";
import { useParams } from "react-router-dom";
import Loader from "src/components/lib/loader/Loader";
import { CheckExpiredMessage } from "src/components/lib/error-messages/CheckExpired";
import ContentWrapper from "src/components/lib/content-wrapper/ContentWrapper";
import { motion } from "framer-motion";

const pageVariants = {
  hidden: { opacity: 0, y: 250 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  exit: { opacity: 0, y: -250, transition: { duration: 0.5 } },
};

const CheckPages = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { checkId } = useParams<{ checkId: string }>();

  const dispatch = useDispatch();
  const fetcher = useCallback(
    async (id: string) => {
      return await getQuestionnaire(id, dispatch);
    },
    [dispatch]
  );

  const { data, isLoading, error } = useSWR(checkId, fetcher, {
    revalidateOnFocus: false,
  });

  const page_no = useSelector((state: any) => state?.page_number.page || "");

  useEffect(() => {
    if (data !== null) {
      dispatch(fetchData(data));
    }
  }, [data, dispatch]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen md:min-h-[70vh]">
        <Loader />
      </div>
    );
  }

  if (error) {
    const errorMessage = error?.response?.data?.message;
    const errorStatus = error?.response?.status;

    return (
      <ContentWrapper className="flex items-center justify-center h-screen w-screen">
        <CheckExpiredMessage label={errorMessage} heading={errorStatus} />
      </ContentWrapper>
    );
  }

  return (
    <PageWrapper ref={containerRef}>
      {page_no === 1 && (
        <div className="h-full overflow-auto">
          <LandingPage
            scrollToNextPage={() => {
              dispatch(nextPage());
            }}
          />
        </div>
      )}
      {page_no === 2 && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={pageVariants}
          className="h-full overflow-auto"
        >
          <TsAndCsPage
            scrollToNextPage={() => {
              dispatch(nextPage());
            }}
          />
        </motion.div>
      )}
      {page_no === 3 && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={pageVariants}
          className="h-full"
        >
          <QuestionnairePage
            scrollToNextPage={() => {
              dispatch(nextPage());
            }}
          />
        </motion.div>
      )}
      {page_no === 4 && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={pageVariants}
          className="h-screen overflow-auto"
        >
          <SuccessPage />
        </motion.div>
      )}
    </PageWrapper>
  );
};

export default CheckPages;
