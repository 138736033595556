import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://0f60ffd979ad835fa51e5e354a9262a5@o4504276778680320.ingest.us.sentry.io/4507808670941184",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV || "development",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/emp\.row\.fragomenworkright\.com/,
    /^https:\/\/dev\.employee\.workright\.fragomen\.io/,
    /^https:\/\/test\.employee\.workright\.fragomen\.io/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
