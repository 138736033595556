import { useDispatch, useSelector } from "react-redux";
import { requestOTP } from "src/services/apiService";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/redux-hooks";
import { InfoCardWithAction } from "@simplecitizen/gdl-react-ui-components";

export interface LandingPageProps {
  scrollToNextPage?: any;
}

export const LandingPage = ({ scrollToNextPage }: LandingPageProps) => {
  const dispatch = useDispatch();
  const { checkId } = useParams<{ checkId: string }>();

  const handleClick = () => {
    scrollToNextPage();
    requestOTP(checkId, "check", dispatch);
  };

  const message = useSelector(
    (state: any) => state?.questionnaire_data?.questionnaire?.message ?? ""
  );

  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );
  return (
    <div className="h-full  md:min-h-[70vh] overflow-auto md:pt-2 pt-4">
      <InfoCardWithAction
        onButtonClick={handleClick}
        content={
          message ||
          "WorkRight has been contracted by your potential new employer to perform a Right to Work check to ensure " +
            "compliance with local employment laws. This process should only take a few minutes to complete. If you " +
            "do not have all the document you need right now, you can click on the same link again later to continue. "
        }
        buttonText="Begin right to work check"
        buttonColor={client?.branding?.primaryColor}
      />
    </div>
  );
};
