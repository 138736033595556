import {
  Title,
  TextInput,
  SegmentedRadioButton,
} from "@simplecitizen/gdl-react-ui-components";

import { useAppDispatch, useAppSelector } from "src/hooks/redux-hooks";
import { clearIdDocs } from "src/store/slices/identityDocCapture";
import { clearNonIdDocs } from "src/store/slices/uploadedNonIdDocsSlice";

interface CitizenShipProps {
  selectedCitizenShip: "citizen" | "not_citizen" | null;
  setSelectedStatusType: React.Dispatch<
    React.SetStateAction<StatusType | null>
  >;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<StatusCategory | null>
  >;
  setSelectedCitizenShip: React.Dispatch<
    React.SetStateAction<"citizen" | "not_citizen" | null>
  >;
  resetStates?: () => void;
}

const CitizenShip = ({
  selectedCitizenShip,
  setSelectedCitizenShip,

  resetStates,
}: CitizenShipProps) => {
  const dispatch = useAppDispatch();
  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );
  const jurisdiction = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.jurisdiction
  );

  return (
    <div>
      <div className="w-full mt-4">
        <Title>Work Country</Title>
        <div className="mt-3">
          <TextInput defaultValue={jurisdiction?.name || ""} disabled={true} />
          <div>
            <div className="font-semibold">
              Are you a citizen of this country?
            </div>
            <div className="grid md:grid-cols-2 gap-2 w-full mt-4">
              <SegmentedRadioButton
                activeColor={client?.branding?.primaryColor || "#0A71C7"}
                name="citizenship"
                id="citizen"
                value="citizen"
                selectedValue={selectedCitizenShip}
                onChange={(e) => {
                  resetStates && resetStates();
                  setSelectedCitizenShip(e.target.value as "citizen");
                  dispatch(clearIdDocs());
                  dispatch(clearNonIdDocs());
                }}
              >
                Yes, I am
              </SegmentedRadioButton>
              <SegmentedRadioButton
                name="citizenship"
                value="not_citizen"
                id="not_citizen"
                activeColor={client?.branding?.primaryColor || "#0A71C7"}
                selectedValue={selectedCitizenShip}
                onChange={(e) => {
                  resetStates && resetStates();
                  setSelectedCitizenShip(e.target.value as "not_citizen");
                  dispatch(clearIdDocs());
                  dispatch(clearNonIdDocs());
                }}
              >
                No, I am not
              </SegmentedRadioButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitizenShip;
