import { isValid } from "date-fns";

export const scrollToElement = (
  elementRef: React.RefObject<HTMLDivElement>
) => {
  if (elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const formatString = (str: string): string => {
  return str
    ?.replace(/[-_]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camelCase words
    .toLowerCase();
};

export function isValidDate(dateString: string): boolean {
  // Try to create a Date object from the string
  const date = new Date(dateString);

  // Check if the date is valid
  return !isNaN(date.getTime());
}

export const formatDate = (dateArg: Date) => {
  const date = new Date(dateArg);

  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
}

export function maskString(value: string): string {
  // Ensure the value is long enough to mask
  if (value.length <= 2) {
    return value; // Return the string as is if it's too short to mask
  }

  // Mask all characters except the last two
  const masked = "*".repeat(value.length - 2) + value.slice(-2);

  return masked;
}
export function parseDate(dateString: string) {
  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is DD/MM/YYYY.");
  }

  // Create and return the Date object
  return new Date(`${year}-${month}-${day}`);
}

export function parseDateToString(dateString: string) {
  // Split the date string into day, month, and year
  const [year, month, day] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is DD/MM/YYYY.");
  }

  // Create and return the Date object
  return `${year}/${day}/${month}`;
}

export function formatToValidDate(dateString: string) {
  // Split the date string into day, month, and year
  const [day, month, year] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is DD/MM/YYYY.");
  }

  // Create and return the Date object
  return `${year}-${month}-${day}`;
}

export function universalDateFormatter(dateString: string) {
  // Split the date string into day, month, and year
  if (!dateString) {
    return "";
  }
  const [year, month, day] = dateString.split("/");

  // Ensure all parts are valid
  if (!day || !month || !year) {
    throw new Error("Invalid date format. Expected format is YYYY-MM-DD.");
  }

  const edgeBrowsweDate = `${day}/${month}/${year}`;
  const chromeDate = `${year}/${month}/${day}`;

  if (isValid(new Date(edgeBrowsweDate))) {
    return edgeBrowsweDate;
  }
  return chromeDate;
}

export function base64ToFile(
  base64String: string,
  filename: string,
  mimeType = "application/octet-stream"
) {
  // Decode the Base64 string
  const byteString = atob(base64String.split(",")[1]);

  // Create an array of byte values
  const byteArray = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  // Create a File object
  return new File([byteArray], filename, { type: mimeType });
}

export function getLastThreeCharacters(str: string): string {
  return str.replace(/\s+/g, "").slice(-3);
}


// Function to find the status type
export function findStatusType(str: string, statusTypes: StatusType[]) {
  // Remove extra spaces and get the last 3 characters of the input string
  const lastThree = str.replace(/\s+/g, "").slice(-3).toLowerCase();

  // Find the status type where a token in the name matches the last three characters
  return statusTypes.find((status: StatusType) => {
    // Split the sanitized name into words
    const tokens = status.name.replace(/\s+/g, " ").toLowerCase().split(" ");
    return tokens.includes(lastThree);
  });
}


export function capitalizeEachWord(str: string): string {
  if (!str) return str; // Return as is if the string is empty or falsy
  return str
    .split(' ') // Split the string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(' '); // Join the words back into a single string
}