import { DatePicker } from "@simplecitizen/gdl-react-ui-components";
import React from "react";
import { FieldErrors, useController } from "react-hook-form";
import { useAppSelector } from "src/hooks/redux-hooks";

interface DateInputProps {
  control: any;
  dataCollector: DataCollector;
  errors: FieldErrors<any>
}

const DateInput = ({ control, dataCollector,errors }: DateInputProps) => {
  const { field } = useController({
    name: dataCollector.key,
    control,
    rules: {
      required: dataCollector.label ? `${dataCollector.label} is required` : 'This field is required',
    }
  });

  const client = useAppSelector(
    (state) => state.questionnaire_data.questionnaire?.client
  );

  return (
    <DatePicker
    error_message={errors[dataCollector?.key]?.message}
      datePickerClassName="top-[4.3rem]"
      className="-mt-2"
      color={client?.branding?.primaryColor || "#0A71C7"}
      labelClassName="font-normal"
      value={field?.value ? new Date(field?.value ) : new Date()}
      onDateChange={(date: Date) => {
        field.onChange(date.toISOString());
      }}
      label={dataCollector.label}
    />
  );
};

export default DateInput;
